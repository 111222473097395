
import React from 'react';

function Footer() {
    return (
        <nav className="flex items-center flex-wrap bg-gradient-to-r from-gray-900 via-purple-500 to-purple-800 p-5">
            <div className="flex items-center flex-shrink-0 text-white mr-6">
                <span className="font-semibold text-xl tracking-tight">HAL-2024</span>
            </div>

        </nav>
    );
}

export default Footer;
