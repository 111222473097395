import React from 'react';
import AudioPlayer from './audioPlayer'; // Importiere die AudioPlayer-Komponente

function beatYourEnemy() {
    return (
        <>
            <div className="mx-auto w-full max-w-sm m-2 p-2 bg-gradient-to-r from-gray-900 via-purple-500 to-purple-800 rounded-lg">
                <h5 className="mb-3 text-white text-center text-2xl dark:text-white">Fuck it All</h5>
                <ul className="my-4 space-y-3">
                    <li>
                        <button className="rounded-full bg-pink-600  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/mac-quack.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">Duck</span>

                    </li>
                    <li>
                        <button className="rounded-full bg-pink-600  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/zonk.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">Zonk</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-pink-600 text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/yes-yes.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">Yes Yes</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-pink-600  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/jump.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">Jump</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-pink-600  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/kururinnn.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">Kururinnn</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-pink-600  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/mwahahaha.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">Mwahahaha</span>
                    </li>

                </ul>
                <div>
                    <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl dark:text-white">

                    </h5>
                </div>
            </div>

        </>
    );
}

export default beatYourEnemy;
