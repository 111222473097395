/* Navbar.js */

import React from 'react';

function Navbar() {
    return (
        <nav className="flex items-center flex-wrap bg-gradient-to-r from-gray-900 via-purple-500 to-purple-800 p-5">
            <div className="flex items-center  text-center flex-shrink-0 text-white ">
                <h5 className="my-3 text-gray-400 text-center text-2xl dark:text-white">Soundboard of my Life</h5>

            </div>

        </nav>
    );
}

export default Navbar;
