import React from 'react';
import AudioPlayer from './audioPlayer'; // Importiere die AudioPlayer-Komponente

function pushYourteam() {
    return (
        <>
            <div className="mx-auto w-full max-w-sm m-2 p-2 bg-gradient-to-r from-gray-900 via-purple-500 to-purple-800 rounded-lg">
                <h5 className="mb-3 text-white text-center text-2xl dark:text-white">Push</h5>
                <ul className="my-4 space-y-3">

                    <li>
                        <button className="rounded-full bg-green-500  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/die_GRUENEN.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">Die Grünen</span>

                    </li>
                    <li>
                        <button className="rounded-full bg-green-500  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/andTHX.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">and THX</span>

                    </li>
                    <li>
                        <button className="rounded-full bg-green-500  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/boxing-bell.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">Ring frei</span>

                    </li>

                    <li>
                        <button className="rounded-full bg-green-500  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/fuckUall.mp3" />
                        </button>
                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">fuck it all</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-green-500  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/schoen-final.mp3" />
                        </button>

                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">Übertrieben schön</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-green-500  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/f1-team-radio.mp3" />
                        </button>

                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">F1</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-green-500  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/Lando.mp3" />
                        </button>

                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">Lando</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-green-500  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/IseeWhatsHappingHere.mp3" />
                        </button>

                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">I See</span>
                    </li>
                    <li>
                        <button className="rounded-full bg-green-500  text-white font-bold w-20 h-20">
                            <AudioPlayer audioSrc="/audio/You're Welcome.mp3" />
                        </button>

                        <span className="flex-1 ms-3 text-2xl text-gray-300 whitespace-nowrap">you're Welcome</span>
                    </li>
                </ul>
                <div>

                </div>
            </div>
        </>
    );
}

export default pushYourteam;
